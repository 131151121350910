.fourth-heghlight-wrapper .left-side-wrapper .left-side-container{
	background-image:  url("../../../xximages/home/forth_left.png"); 
	background-color: #000;
	padding-top: 35px;
	margin: 0 0 10px 0;
	background-size: cover;		
}
.fourth-heghlight-wrapper .right-side-wrapper .right-side-container{
	background-image:  url("../../../xximages/home/forth_right.png"); 
	background-color: #318b8e;
	background-position: bottom;
	padding-top: 35px;
	margin: 0 0 10px 0;
	background-size: contain;	
}

@media (min-width: 768px) {
.fourth-heghlight-wrapper .left-side-wrapper .left-side-container {
    margin: 10px 5px 5px 10px;
}
.fourth-heghlight-wrapper .right-side-wrapper .right-side-container {
    margin: 10px 10px 5px 5px;
}
}		