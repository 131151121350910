.first-hightlight-wrapper{
    background-image: url(../../../xximages/home/show4.png);
    height: 900px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: initial;
    background-color: #fbfbfd;
    text-align: center;
    padding: 65px 20px;
    color: #fff;	
    margin-bottom: 10px;
}
.ipod-caption {
    color: #86868b;
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;		
    padding-top: 310px;
}

/* Meadia Query */
@media (min-width: 768px) {
    .first-hightlight-wrapper {
        background-image: url("../../../xximages/home/show4.png");
        min-height: 900px;
    }	
    .ipod-caption {
    padding-top: 415px;
    }	
}
